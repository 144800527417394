import React, { useMemo } from 'react';

import { IMonthEndClosingProps } from '@src/types/common_operational_dashboard';
import { formatDate } from '@src/utils/date_helpers';

import NumberBox from '@src/components/operational_dashboard/components/custom_cells/number_box';
import { auditScanTooltipData, missingBankStatementTooltipData } from '@src/components/operational_dashboard/helper';
import { ICommonProps } from '@src/components/operational_dashboard/types';
import EncryptedIcon from '@src/components/utils/icomoon/encrypted';
import { Button } from '@src/components/ui_v2/buttons';
import { NotepadIcon } from '@src/components/utils/icomoon';

import AuditScanItem from './audit_scan_item';
import HoverTooltipWrapper from './hover_tooltip_wrapper';
import ReconciliationItem from './reconciliation_item';
import { encryptedIconColor } from './utils';
import { useMarkBookAsClosedModal } from '../mark_book_as_closed';

import styles from './style.module.scss';

type IMonthEndClosingCellProps = IMonthEndClosingProps & ICommonProps & {
  handleNotesViewClick: () => void;
};

const MonthEndClosingCell = ({
  missingBankStatements,
  auditScan,
  auditScanStatus,
  lastCloseDate,
  lastAuditScanDate,
  notes,
  preferences,
  setupStatus,
  handleNotesViewClick,
  generalLedgerConnectionIssues,
  auditId,
  businessId,
}: IMonthEndClosingCellProps) => {
  const {
    Component: MarkBookAsClosedModal,
    props: markBookAsClosedModalProps,
    openWithValue: openMarkBookAsClosedModalWithValue,
  } = useMarkBookAsClosedModal();

  const renderGeneralLedgerConnectionIssues = useMemo(() => {
    if (generalLedgerConnectionIssues) {
      return <NumberBox value={ { value: true, type: 'indicator' } } />;
    }
    return <NumberBox value={ { value: 0, type: 'number' } } />;
  }, [generalLedgerConnectionIssues]);

  return (
    <div className={ `${styles['business-reconciliation-container']} ${styles['expense-header-container']}` }>
      <MarkBookAsClosedModal
        { ...markBookAsClosedModalProps }
      />
      {preferences?.monthEndClosing?.missingBankStatements
        && (missingBankStatements !== undefined ? (
          <div className={ styles['header-item'] }>
            <ReconciliationItem
              setupStatus={ setupStatus }
              tooltipData={ missingBankStatementTooltipData }
              tooltipValue={ missingBankStatements }
              value={ missingBankStatements }
            />
          </div>
        ) : (
          <div className={ styles['header-item'] }>
            {renderGeneralLedgerConnectionIssues}
          </div>
        ))}
      {preferences?.monthEndClosing?.auditScan && (auditScan !== undefined && auditScan !== null ? (
        <div className={ styles['header-item'] }>
          <AuditScanItem
            auditScanStatus={ auditScanStatus ?? 'completed' }
            generalLedgerConnectionIssues={ generalLedgerConnectionIssues ?? false }
            lastAuditedOn={ formatDate(lastAuditScanDate) }
            tooltipData={ auditScanTooltipData }
            value={ auditScan }
          />
        </div>
      ) : (
        <div className={ styles['header-item'] }>
          {renderGeneralLedgerConnectionIssues}
        </div>
      ))}
      {preferences?.monthEndClosing?.lastCloseDate && (!generalLedgerConnectionIssues && (lastCloseDate !== undefined && lastCloseDate !== null) ? (
        <div className={ styles['header-item'] }>
          <HoverTooltipWrapper
            content={ (
              <div className={ styles['hover-tooltip-content-last-close-date'] }>
                <Button
                  size="compact"
                  variant="link"
                  onClick={ () => {
                    openMarkBookAsClosedModalWithValue({
                      auditId:    auditId ?? 0,
                      businessId: businessId ?? 0,
                    });
                  } }
                >
                  Mark Books As Closed
                </Button>
              </div>
            ) }
          >
            { formatDate(lastCloseDate) }
          </HoverTooltipWrapper>
        </div>
      ) : (
        <div className={ styles['header-item'] }>
          {renderGeneralLedgerConnectionIssues}
        </div>
      ))}

      {preferences?.monthEndClosing?.notes && (
        <div className={ styles['header-item'] }>
          {notes !== undefined ? (
            <NotepadIcon fontSize={ 16 } onClick={ handleNotesViewClick } />
          ) : (
            <NumberBox value={ { value: 0, type: 'number' } } />
          )}
        </div>
      )}
    </div>
  );
};
export default MonthEndClosingCell;
