import { useMemo, useEffect } from 'react';

import { reduce } from 'lodash';
import omit from 'lodash/omit';
import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import { UseQueryResult } from 'react-query';

import { Section } from '@src/constants/sections';
import { useGetIntegrationsRuns } from '@src/hooks/queries/integrations/runs';
import { useGetRevenueServiceDocuments } from '@src/hooks/queries/revenue_service_documents';
import { useSorting } from '@src/hooks/url_params';
import { IGetNoRevenueDatesResponse } from '@src/requests/no_revenue_dates';
import { IGetRevenueServiceDocumentsResponse } from '@src/requests/revenue_service_documents';
import { TID, TSection } from '@src/types/common';
import { IIntegrationsRun } from '@src/types/integrations/runs';
import { INoRevenueDate } from '@src/types/no_revenue_dates';
import { IPaymentProcessor } from '@src/types/payment_processors';
import { IRevenueReportType } from '@src/types/revenue_report_types';
import {
  IRevenueServiceDocument,
  IRevenueServiceDocumentsFilter,
  IRevenueServiceDocumentsQueryFilter,
  TRevenueServiceDocumentsSortColumn,
} from '@src/types/revenue_service_documents';
import { IRevenueService } from '@src/types/revenue_services';
import { IRevenueSystem } from '@src/types/revenue_systems';
import { ISorting, ISortingParams } from '@src/types/sorting';
import {
  apiDaysForMonth,
  endOfMonthApiDate,
  formatApiDate,
  formatApiMonth,
  parseApiMonth,
  startOfMonthApiDate,
} from '@src/utils/date_helpers';

import { emptyRSD, useNoRevenueDateRecords } from '@src/components/revenue_center/utils';
import { useClearFilter, useFilterData } from '@src/components/ui_v2/filter';

interface IUseRSDCollectionParams {
  revenueService: IRevenueService,
  revenueSystem: IRevenueSystem,
}

const defaultSorting: ISortingParams<TRevenueServiceDocumentsSortColumn> = {
  orderColumn:    'end_date',
  orderDirection: 'desc',
};

const defaultFilter = {
  month: formatApiMonth(new Date()),
};

interface IRSDCollection {
  queries: [
    UseQueryResult<IGetRevenueServiceDocumentsResponse, Error>,
    UseQueryResult<IGetNoRevenueDatesResponse, Error>,
  ],
  records: IRevenueServiceDocument[],
  section: TSection,
  sorting: ISorting<TRevenueServiceDocumentsSortColumn>,
  runsRecords: IIntegrationsRun[],
  integrationId: TID | null,
}

const processStatusForFilter = (
  data: IRevenueServiceDocumentsFilter,
): IRevenueServiceDocumentsQueryFilter => {
  const queryData: IRevenueServiceDocumentsQueryFilter = {};

  switch (data?.status) {
    case 'balanced':
      queryData.state = 'verified';
      break;
    case 'unbalanced':
      queryData.state = 'verifying';
      break;
    default:
      return queryData;
  }
  return queryData;
};

const filterDataToQuery = (
  data: IRevenueServiceDocumentsFilter,
  allPaymentProcessors: IPaymentProcessor[],
): IRevenueServiceDocumentsQueryFilter => {
  const processedStatusForFilter = processStatusForFilter(data);
  const mergedFilterData = { ...data, ...processedStatusForFilter };
  const queryData = omit(mergedFilterData, ['month', 'status']);
  if (data?.month) {
    const date = parseApiMonth(data.month);

    queryData.endDate = {
      gte: startOfMonthApiDate(date),
      lte: endOfMonthApiDate(date),
    };
  }

  const ppCodes = allPaymentProcessors.map((pp) => pp.code);
  return reduce(queryData, (result, value, key) => {
    if (!ppCodes.includes(key)) return { ...result, [key]: value };

    return {
      ...result,
      paymentProcessors: [
        ...(result.paymentProcessors || []),
        {
          code: key,
          value,
        },
      ],
    } as IRevenueServiceDocumentsQueryFilter;
  }, {} as IRevenueServiceDocumentsQueryFilter);
};

const shouldReturnRecords = (
  filter: IRevenueServiceDocumentsFilter | undefined,
): boolean => {
  if (!filter || (Object.keys(filter).length === 1 && filter.month)) {
    return false;
  }
  return filter.status !== 'no_revenue_day' && filter.status !== 'missing';
};

const processRecordsByState = (
  filter: IRevenueServiceDocumentsFilter | undefined,
  noRevenueDates: INoRevenueDate[],
  days: string[],
  recordsWithNoRevenueDates: IRevenueServiceDocument[],
  revenueServiceId: TID,
): IRevenueServiceDocument[] => {
  const status = filter?.status;
  switch (status) {
    case 'no_revenue_day':
      return noRevenueDates.map(({ date }) => emptyRSD(revenueServiceId, date, noRevenueDates));

    case 'missing':
      return days.reduce((documents, date) => {
        if (!recordsWithNoRevenueDates.some((record) => record.endDate === date)
            && !noRevenueDates.some((noRevenueDate) => noRevenueDate.date === date)) {
          documents.push(emptyRSD(revenueServiceId, date, noRevenueDates));
        }
        return documents;
      }, [] as IRevenueServiceDocument[]);

    default:
      return days.map((date) => {
        const document = recordsWithNoRevenueDates.find((d) => d.endDate === date);
        return document || emptyRSD(revenueServiceId, date, noRevenueDates);
      });
  }
};

const recordsWithMissedDays = (
  revenueServiceId: TID,
  records: IRevenueServiceDocument[],
  noRevenueDates: INoRevenueDate[],
  filter: IRevenueServiceDocumentsFilter | undefined,
  sortingData: ISortingParams<TRevenueServiceDocumentsSortColumn>,
): IRevenueServiceDocument[] => {
  if (shouldReturnRecords(filter)) {
    return records;
  }
  let days = apiDaysForMonth(filter?.month, { untilToday: true });

  const recordsWithNoRevenueDates = records.map((d) => {
    const nrd = noRevenueDates.find((n) => n.date === d.endDate);
    if (nrd) {
      const docNrd: IRevenueServiceDocument = { ...d, status: 'no_revenue_day' };
      return docNrd;
    }

    return { ...d };
  });

  if (sortingData.orderColumn === 'end_date') {
    if (sortingData.orderDirection === 'desc') {
      days = reverse(days);
    }

    return processRecordsByState(
      filter,
      noRevenueDates,
      days,
      recordsWithNoRevenueDates,
      revenueServiceId,
    );
  }

  const existDays = recordsWithNoRevenueDates.map((r) => r.endDate);
  let filteredDates;

  if (sortingData.orderDirection === 'asc') {
    filteredDates = days.filter((d) => !existDays.includes(d));
  } else {
    filteredDates = reverse(days).filter((d) => !existDays.includes(d));
  }
  const status = filter?.status;
  switch (status) {
    case 'no_revenue_day':
    case 'missing':
      return processRecordsByState(
        filter,
        noRevenueDates,
        filteredDates,
        recordsWithNoRevenueDates,
        revenueServiceId,
      );
    default:
      return [
        ...filteredDates.map((date) => emptyRSD(revenueServiceId, date, noRevenueDates)),
        ...recordsWithNoRevenueDates,
      ];
  }
};

const usePaymentProcessorsForRevenueReportType = (
  revenueReportType: IRevenueReportType | undefined,
  revenueService: IRevenueService,
): IPaymentProcessor[] => {
  return useMemo(() => {
    const revenueServiceCategoryIdsForSystem =
      revenueReportType?.revenueReportTypeCategories.map((a) => a.revenueServiceCategoryId) || [];
    const pps = uniqBy(revenueService.revenueServiceCategories.filter((r) => {
      return r.paymentProcessorId !== null && revenueServiceCategoryIdsForSystem.includes(r.id);
    }), (r) => r.paymentProcessorId).map((sc) => {
      return {
        id:   sc.paymentProcessorId!,
        name: sc.paymentProcessorName!,
        code: sc.paymentProcessorCode!,
      };
    });
    return sortBy(pps, ['name']);
  }, [revenueReportType, revenueService]);
};

const mergeStatusAndErrorIntoDocuments = (documents: IRevenueServiceDocument[], runs: IIntegrationsRun[] | undefined): IRevenueServiceDocument[] => {
  if (runs) {
    const runStatusMap = runs.reduce((acc, run) => {
      acc[run.endDate] = {
        runStatus:          run.statusMessage,
        runError:           run?.error,
        lastUpdatedAt:      run?.lastUpdatedAt,
        restartRequestedBy: run?.restartRequestedBy,
      };
      return acc;
    }, {} as { [key: string]: { runStatus: string; runError?: string, lastUpdatedAt?: string, restartRequestedBy?: string } });
    return documents.map((document) => {
      const runInfo = runStatusMap[document.endDate!] as any;
      return {
        ...document,
        runStatus:          runInfo?.runStatus,
        runError:           runInfo?.runError,
        lastUpdatedAt:      runInfo?.lastUpdatedAt,
        restartRequestedBy: runInfo?.restartRequestedBy,
      };
    });
  }
  return documents;
};


const useRSDCollection = ({
  revenueService,
  revenueSystem,
}: IUseRSDCollectionParams): IRSDCollection => {
  const section = useMemo(() => {
    return {
      revenueSystemId: revenueSystem.id,
      section:         Section.RevenueCapture,
    };
  }, [revenueSystem]);
  const revenueReportType = useMemo(() => {
    return revenueService.revenueReportTypes.find((item) => {
      return item.id === revenueSystem.revenueReportTypeId;
    });
  }, [revenueService.revenueReportTypes, revenueSystem.revenueReportTypeId]);
  const allPaymentProcessors = usePaymentProcessorsForRevenueReportType(
    revenueReportType,
    revenueService,
  );
  const clearFilter = useClearFilter(section);
  useEffect(() => clearFilter(), [clearFilter]);

  const filterData = useFilterData(section, defaultFilter);

  const filterQuery = useMemo(() => {
    return filterDataToQuery(
      filterData,
      allPaymentProcessors,
    );
  }, [filterData, allPaymentProcessors]);

  const sorting = useSorting<TRevenueServiceDocumentsSortColumn>({
    section: section.section,
    defaultSorting,
  });

  const isStateUnbalanced = filterQuery?.state === 'verifying';
  const filteredQuery = isStateUnbalanced
    ? omit(filterQuery, 'state')
    : filterQuery;
  const filterParams = {
    revenueServiceId: revenueService.id,
    revenueSystemId:  revenueSystem.id,
    filter:           filteredQuery,
    ...sorting.data,
    ...(isStateUnbalanced && { state: ['verifying', 'modifying'] }),
  };
  const query = useGetRevenueServiceDocuments(filterParams);

  const { noRevenueDatesRecords, noRevenueDatesQuery } = useNoRevenueDateRecords({
    revenueSystem,
    filterData,
  });

  const records: IRevenueServiceDocument[] = useMemo(() => {
    if (query.isLoading) return [];

    // Start date has wrong format in the RevenueServiceDocument API
    const fixedDays = (query?.data?.collection || []).map((d) => ({
      ...d,
      startDate: formatApiDate(d.startDate),
      rowKey:    `${formatApiDate(d.startDate)}-${d.id}`,
      endDate:   formatApiDate(d.endDate),
    })) || [];

    return recordsWithMissedDays(
      revenueService.id,
      fixedDays,
      noRevenueDatesRecords,
      filterData,
      sorting.data,
    );
  }, [filterData, query.data?.collection, query.isLoading,
    revenueService.id, sorting.data, noRevenueDatesRecords]);

  const integrationId = revenueSystem.integrationId || 0;
  const startDate = filterParams?.filter?.endDate?.gte || startOfMonthApiDate(new Date());
  const endDate = filterParams?.filter?.endDate?.lte || endOfMonthApiDate(new Date());

  const runsQuery = useGetIntegrationsRuns({
    integrationId,
    startDate,
    endDate,
  });
  const runsRecords: IIntegrationsRun[] = useMemo(() => {
    if (runsQuery.isLoading) return [];

    // Start date has wrong format in the RevenueServiceDocument API
    return runsQuery?.data?.collection || [];
  }, [runsQuery.data?.collection, runsQuery.isLoading]);
  const queries: [
    UseQueryResult<IGetRevenueServiceDocumentsResponse, Error>,
    UseQueryResult<IGetNoRevenueDatesResponse, Error>,
  ] = useMemo(() => ([
    query,
    noRevenueDatesQuery,
  ]), [query, noRevenueDatesQuery]);

  const mergedRecords: IRevenueServiceDocument[] = useMemo(() => {
    return mergeStatusAndErrorIntoDocuments(records, runsRecords);
  }, [records, runsRecords]);
  return {
    queries,
    records: mergedRecords,
    section,
    sorting,
    runsRecords,
    integrationId: revenueSystem.integrationId || null,
  };
};

export {
  IRSDCollection,
  usePaymentProcessorsForRevenueReportType,
  useRSDCollection,
};
