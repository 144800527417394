import React, { useCallback, useEffect, useState } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';

import DetailsRegion from '@src/components/ui_v2/layout/details_region';

import { useTSDCollection } from './hooks';
import List from './list';
import Sidebar from './sidebar';

import styles from './styles.module.scss';

const AllTransactions = () => {
  const business = useBusinessContext();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [rowData, setRowData] = useState<ITransactionServiceDocument | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const tsdCollection = useTSDCollection({ businessId: business.id, excluded: false });

  const totalTransactions = tsdCollection.query.data?.pages[0].meta.totalCount;
  const totalAmount = tsdCollection.query.data?.pages[0].meta.totalAmount || 0;
  const formattedTotalAmount = totalAmount > 0 ? `$${Math.abs(totalAmount)}` : `-$${Math.abs(totalAmount)}`;

  const updateRowData = useCallback((currentRowData: ITransactionServiceDocument | null) => {
    if (currentRowData) {
      const pages = tsdCollection.query.data?.pages || [];
      const updatedData = pages.flatMap((page) => page.collection).find(
        (item: ITransactionServiceDocument) => item.id === currentRowData.id,
      );

      setRowData(updatedData || currentRowData);
    }
  }, [tsdCollection.query.data]);

  const handleRowClick = async (data: ITransactionServiceDocument) => {
    setIsLoading(true);

    updateRowData(data);

    setIsLoading(false);
    setIsSidebarOpen(true);
  };

  const handleRefreshData = async () => {
    await tsdCollection.query.refetch();
  };

  useEffect(() => {
    const handleAddDocument = () => {
      // @ts-ignore
      const businessModel = new window.Docyt.Entities.Business(business);

      // @ts-ignore
      const services = business.services || [];
      
      const rcService = services.find((service: any) => service.type === 'reconciliation_center');
      const biService = services.find((service: any) => service.type === 'business_intelligence');
      
      const rcServiceObj = {
        ...rcService,
        get(key: string) { return rcService?.[key]; }
      };

      const biServiceObj = {
        ...biService,
        get(key: string) { return biService?.[key]; }
      };
      
      // @ts-ignore
      const modalView = new window.Docyt.AdvisorHomeApp.Businesses.Show.Services.Show.ReconciliationCenter.TransactionAddDocumentModalView({
        business: businessModel,
        rc_service: rcServiceObj,
        bi_service: biServiceObj,
        related_documents: null,
      });

      // @ts-ignore
      window.Docyt.modalRegion.show(modalView);

      // Listen for the document completion event and refresh the data.
      // @ts-ignore
      window.Docyt.vent.once('add:related:document:done', handleRefreshData);
    };

    const handleNoDocumentNeeded = (documentId: string) => {
      const model = {
        id: documentId,

      // @ts-ignore
        get: function(key: string) { return this[key]; },
      // @ts-ignore
        set: function(key: string, value: any) { this[key] = value; },
        toJSON: function() { return this; },
        trigger: function() {},
        attributes: {
          id: documentId
        }
      };

      // @ts-ignore
      const modalView = new window.Docyt.AdvisorHomeApp.Businesses.Show.Services.Show.ReconciliationCenter.AddDocumentNoteModal({
        model: model
      });

      modalView.on('document:note:added', async (documentNote: string) => {
        try {
          await fetch(`/api/v1/transaction_service_documents/${documentId}/no_document_required`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              id: documentId,
              no_document_required: true,
              document_note: documentNote
            })
          });
          
          // @ts-ignore
          window.Docyt.vent.trigger('hide:spinner');
          // @ts-ignore
          window.Docyt.vent.trigger('transaction:match:list:added');
          // @ts-ignore
          window.Docyt.vent.trigger('transaction:document:note:added');
          
          handleRefreshData();
        } catch (error) {
          console.error('Error updating document:', error);
        }
      });

      // @ts-ignore
      window.Docyt.secondModalRegion.show(modalView);
    };

    // @ts-ignore
    window.Docyt.vent.on('related:document:add', handleAddDocument);
    // @ts-ignore
    window.Docyt.vent.on('transaction:document:note:add', handleNoDocumentNeeded);

    return () => {
      // @ts-ignore
      window.Docyt.vent.off('related:document:add', handleAddDocument);
      // @ts-ignore
      window.Docyt.vent.off('add:related:document:done', handleRefreshData);
      // @ts-ignore
      window.Docyt.vent.off('transaction:document:note:add', handleNoDocumentNeeded);
    };
  }, [business, handleRefreshData]);

  useEffect(() => {
    updateRowData(rowData);
  }, [tsdCollection.query.data, rowData, updateRowData]);

  return (
    <div className={ styles.container }>
      <DetailsRegion>
        <DetailsRegion.Header title="All Transactions" />
        <div className={ styles['tables-container'] }>
          <List collection={ tsdCollection } onRowClick={ handleRowClick } />
        </div>
        <div className={ styles['total-transactions'] }>
          Total Transactions:
          <span className={ styles['total-transactions-value'] }>{ totalTransactions }</span>
          <span>｜</span>
          Total Amount:
          <span className={ styles['total-transactions-value'] }>{ formattedTotalAmount }</span>
        </div>
      </DetailsRegion>
      { isSidebarOpen && rowData && (
        <Sidebar
          key={ Math.random() }
          businessId={ business.id }
          isLoading={ isLoading }
          rowData={ rowData }
          setRowData={ setRowData }
          onClose={ () => setIsSidebarOpen(false) }
          onRefreshData={ handleRefreshData }
        />
      )}
    </div>
  );
};

export default React.memo(AllTransactions);
