/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable consistent-return */
/* eslint-disable no-console */
import React, { useEffect, useMemo, useState } from 'react';

import { useCreateClientBusiness } from '@src/hooks/queries/businesses';
import { useGetDefaultAccountingFirm } from '@src/hooks/queries/signup';
import { getQboCustomers } from '@src/requests/signup';
import { IBusiness } from '@src/types/businesses';

import OnboardingPage from '../layout/onboarding_page';

import styles from '../styles.module.scss';

const ConnectLedgerProgress = () => {
  const { mutateAsync: createClientBusiness } = useCreateClientBusiness();
  const query = useGetDefaultAccountingFirm();
  const business = useMemo(() => (query?.data as IBusiness) || {}, [query]);

  const [doneCount, setDoneCount] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const percent = Math.floor((doneCount * 100) / totalCount) || 0;

  const callGetQboCustomers = (page: number) => {
    return new Promise<any>((resolve, reject) => {
      getQboCustomers({ businessId: business.id, page })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const fetchAllCustomers = async (currentPage: number, accountingFirmBusinessId: number) => {
    const response = await callGetQboCustomers(currentPage);
    setTotalCount(response?.totalCount || 0);

    if (response?.qboCustomers?.length > 0) {
      for (const customer of response.qboCustomers) {
        if (!customer.clientCompanyId) {
          setDoneCount((count) => count + 1);
          return Promise.resolve();
        }
        try {
          await createClientBusiness({
            accountingFirmBusinessId,
            qboCustomerId: customer.id,
          });
        } catch(error) {
          console.log(error);
        } finally {
          setDoneCount((count) => count + 1);
        }
      }

      await fetchAllCustomers(currentPage + 1, accountingFirmBusinessId);
    } else {
      Backbone.history.navigate('setup_accounting_firm?step=billing', {
        trigger: true,
      });
    }
  };

  useEffect(() => {
    fetchAllCustomers(1, business.id);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [business.id]);

  return (
    <OnboardingPage isHideBorder title="Setting up your account...">
      <div className={ styles['onboarding-form'] }>
        <div className={ styles['onboarding-body'] }>
          <div className="display-flex p-b-20 font-14 justify-content-center">
            Please wait while we setup your account...
          </div>
          <div className={ styles['progress-container'] }>
            <div
              className={ styles['progress-bar'] }
              style={ { width: `${percent}%` } }
            />
          </div>
        </div>
      </div>
    </OnboardingPage>
  );
};

export default React.memo(ConnectLedgerProgress);
