/* eslint-disable import/order */
import React, { memo } from "react";

import styles from "../styles.module.scss";
import ChatContainer from "./chat_container";

const ChatBody = () => {
  return (
    <div className={styles["chat-content-section"]}>
      <ChatContainer />
    </div>
  );
};

export default memo(ChatBody);
