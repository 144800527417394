const getDefaultDataProperty = (activeThreadId: string | undefined, messageSignature: string = '') => {
  // this is to be changed when the actual BE calls are added
  let metaData: Record<string, string | boolean> = {
    user_id: String(window.Docyt.currentAdvisor.get('id')),
    message_signature: messageSignature,
  };
  
  if (activeThreadId) {
    metaData.chat_thread_id = activeThreadId;
  } else {
    metaData.chat_thread_id = '';
    metaData.new_thread = true;
  }
  const data = JSON.stringify(metaData);
  return data;
};

/**
 * Finds an element and its index in an array based on a predicate function
 * @example
 * const users = [
 *   { id: 1, name: 'John' },
 *   { id: 2, name: 'Jane' },
 *   { id: 3, name: 'Bob' }
 * ];
 * 
 * const { element, index } = findWithIndex(users, user => user.id === 2);
 * // Returns: { element: { id: 2, name: 'Jane' }, index: 1 }
 * 
 * const notFound = findWithIndex(users, user => user.id === 4);
 * // Returns: { element: undefined, index: -1 }
 */
const findWithIndex = <T>(array: T[], predicate: (value: T, index: number, obj: T[]) => boolean): { element: T | undefined, index: number } => {
  const index = array.findIndex(predicate);
  return {
    element: index !== -1 ? array[index] : undefined,
    index
  };
};


export {
  getDefaultDataProperty,
  findWithIndex
};
