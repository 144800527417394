import React, {
  ChangeEvent,
  forwardRef,
  useCallback,
  useRef,
  useState,
} from "react";

import classNames from "classnames";
import { mergeRefs } from "react-merge-refs";

import InputIndicatorWrapper from "@src/components/ui_v2/inputs/input_indicator_wrapper";

import styles from "../styles.module.scss";

interface ITextAreaInputProps
  extends Omit<React.ComponentPropsWithoutRef<"textarea">, "size"> {
  suffixIcon?: React.ReactNode;
  hideClear?: boolean;
  wrapperClasses?: string;
}

const TextAreaWithSuffixIcon = forwardRef<
  HTMLTextAreaElement,
  ITextAreaInputProps
>(
  (
    {
      className = "",
      value = "",
      onChange,
      suffixIcon,
      hideClear,
      wrapperClasses = "",
      ...inputProps
    }: ITextAreaInputProps,
    ref
  ): JSX.Element => {
    const [internalValue, setInternalValue] = useState<string>("");
    const inputRef = useRef<HTMLTextAreaElement>(null);

    const classes = classNames(
      styles["text-input"],
      styles["no-resize"],
      className
    );

    const handleClear = useCallback(() => {
      setInternalValue("");

      if (inputRef.current) {
        const event = new Event("input", { bubbles: true });
        inputRef.current.dispatchEvent(event);
      }
    }, []);

    const handleChange = useCallback(
      (e: ChangeEvent<HTMLTextAreaElement>) => {
        setInternalValue(e.currentTarget.value);

        if (onChange) onChange(e);
      },
      [onChange]
    );

    return (
      <InputIndicatorWrapper
        className={wrapperClasses}
        hasValue={Boolean(value || internalValue)}
        hideClear={hideClear}
        suffixIcon={suffixIcon}
        onClear={handleClear}
      >
        <textarea
          ref={mergeRefs([inputRef, ref])}
          className={classes}
          value={value}
          onChange={handleChange}
          {...inputProps}
        />
      </InputIndicatorWrapper>
    );
  }
);

TextAreaWithSuffixIcon.displayName = "TextAreaInput";

export default React.memo(TextAreaWithSuffixIcon);
